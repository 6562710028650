
/** to add hover effect on icon **/
.filter-hover {filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(235deg) brightness(103%) contrast(103%);}
.filter-black {filter: none;}
.filter-normal {filter: invert(65%) sepia(99%) saturate(1159%) hue-rotate(359deg) brightness(102%) contrast(105%);}

.icon {
    a {
        background-color: inherit;
        position: relative;
        .overlay {
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top:0;
            background-color: $bg-color-nav-highlight;
            mix-blend-mode: multiply;
            transition: all 0.2s ease-in;
            // for IE explorer fallback
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                background: transparentize($color-black, 0.5);
            }
            // For Edge fallback
            @supports (-ms-ime-align:auto) {
                background: transparentize($color-black, 0.5);
            }
        }
        &:hover {
            .overlay {
                opacity: 1;
                visibility: visible;
                transition: all 0.2s ease-out;
            }
        }
    }
}
