// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

a.ls-gui-element.ls-nav-prev, a.ls-gui-element.ls-nav-next {
    display: none;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';


@import 'sections/section-estimation';
/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


section.section-emplois-offerts {
  padding: 50px 15px;

  div.header-offert {
    padding: 20px;
    margin-top: 30px;
    background-color: $color-primary;
    h3 {
      color: $color-black;
    }
  }

  div.description-offert {
    padding: 20px;
    h3 {
      color: $color-black;
      padding: 20px 0;
    }
    p {
      font-weight: 400;
    }
  }
}

/*****************************************************/
/*  POPUP
/*****************************************************/
.product-popup {
    position: relative;
    background: white;
    padding: 20px;
    width: auto;
    max-width: 90%;
    margin: 20px auto;

    .product-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .product-image, .product-info {
            width: 50%;
            padding: 10px;
            @media screen and (max-width: $size-xs-max){
              width: 80%;
            }
        }
        .product-info {
            padding: 10px 0;
        }

    }

    img.mfp-img {
        padding: 0;
    }
    .title {
        padding: 20px 0;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
    }

    div.info {
        @extend .flex;
        @extend .items-center;
        @extend .justify-between;
        flex-direction: column;
    }
    div.description {
        padding: 10px 0;
        //@extend .font-1;

        text-align: justify;
        p {
          font-size: 18px;
          color: $color-grey;
          font-weight: 400;
          text-align: center;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .title,.categories, div.description {
            font-size: 18px;
            text-align: center;
            padding: 0;
            p {text-align: center;}
        }
        .product-wrapper {
            flex-direction: column;
            .product-info .info {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.mfp-close-btn-in .mfp-close {
    // color: $color-yellow;
    height: 24px;
    line-height: 24px;
    width: 24px;
}
button.mfp-arrow-right {
    // right: calc(100vw / 2 - 420px);
}
button.mfp-arrow-left {
    // left: calc(100vw / 2 - 420px);
}

/*****************************************************/
/* LOI 25
/*****************************************************/

a.btn-loi {
    width: 100px;
    position: fixed;
    left: 10px;
    z-index: 30;
    bottom: 15px;
    @media screen and (max-width: $size-xs-max) {
        width: 75px;
        left: initial;
        right: 10px;
    }
}


/***************************************************/

.module-header--caption--subtitle {
    width: 100%;
    text-align: center;
    font-size: 1.25vw;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 10px #000;
    background: url("../images/sous-menu_bg.jpg") no-repeat;
    height: 38px;
    text-transform: uppercase;
}
nav.module-menu .menu-navbar .main-menu-top .logoDroite {
    position: absolute;
    right: 2%;
    top: 5%;
}



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        margin-bottom: -3px;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 50%;
            width: 100%;
            text-align: center;
            z-index: $z-index-slide-caption;
            h3 {
                font-size: $font-size-30;
                font-weight: normal;
                color: #fff;
                padding-bottom: 5px;
                text-shadow: 2px 2px 5px #000, -2px -2px 5px #fff, 2px -2px 5px #000, -2px 2px 5px #000;
            }

            h2 {
                font-size: $font-size-60;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
                text-shadow: 2px 2px 5px #000, -2px -2px 5px #fff, 2px -2px 5px #000, -2px 2px 5px #000;
            }
        }
        @media screen and (max-width: $size-md-max) {
            &_caption {
                top: 45%;
            }
        }
    }
}

#blocBackground {
    text-align: center;
    padding: 100px 0;
    background: url("../images/accueil_section01_paralax_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #fefefe;
        line-height: 1.5;
    }

}

#bloc50-50 {
    display: flex;

    .left-side {
    width: 50%;
    }
    .right-side {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;

        h3 {
            font-size: $font-size-30;
            font-weight: bold;
            color: #242422;
            text-transform: initial;
            padding-bottom: 50px;
        }
        h4 {
            font-size: $font-size-18;
            font-weight: bold;
            color: #242422;
            padding-bottom: 20px;
        }
        ul {
            padding-left: 50px;

            li {
                font-size: $font-size-18;
                font-weight: normal;
                color: #242422;
                text-transform: initial;
                margin-bottom: -15px;
            }
        }
    }
}

#blocRealisation {
    text-align: center;
    padding: 50px 0;
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;

    p {
        font-size: $font-size-18;
        font-weight: bold;
        color: #242422;
    }
    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #242422;
    }
}

#blocLogo {
    display: flex;
    padding: 50px 0;
    justify-content: space-around;
    align-items: center;
}


/*******************************************************************************
* QUI NOUS SOMMES
*******************************************************************************/

#blocIntro_sommes {
    text-align: center;
    padding: 100px 0;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #000;
        line-height: 1.5;
        width: 77%;
        margin: auto;
    }
}

#blocTexteBackground {
    padding: 100px 0 100px 60%;
    background: url("../images/quinoussommes_section02_bg.jpg") no-repeat;
    background-size: cover;

    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #000;
    }
}

#blocDifferences {
    padding: 100px 5% 55px 8%;
    position: relative;

    ::before {
        content:"";
        position: absolute;
        bottom: 0;
        right: 10%;
        background: url("../images/quinoussommes_section03_img01.jpg") no-repeat;
        width: 371px;
        height: 532px;
    }

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #242422;
        text-transform: initial;
        padding-bottom: 50px;
        }
        .bloc {
            padding-left: 65px;
            h4 {
                font-size: $font-size-18;
                font-weight: bold;
                color: #242422;
                padding-bottom: 20px;
            }
            ul {
                padding-left: 50px;

                li {
                    font-size: $font-size-18;
                    font-weight: normal;
                    color: #242422;
                    text-transform: initial;
                    margin-bottom: -15px;
                }
            }
         }
    }

#blocBackground_sommes {
    text-align: center;
    padding: 50px 0;
    background: url("../images/quinoussommes_section04_bg.jpg") no-repeat;
    background-size: cover;

    p {
        font-size: $font-size-18;
        font-weight: bold;
        color: #fff;
    }
}

#bloc50-50_logo {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0;

    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #242422;
        text-transform: initial;
        margin-bottom: 15px;
        }

    .blocLogo {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .left-side {
        width: 50%;
        padding: 0 0 0 10%;
        text-align: center;
    }
    .right-side {
        width: 50%;
        text-align: center;
    }
}



/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#blocFormulaire {
    padding: 100px 0;

    #right {
    text-align: right;

        label {
            font-size: $font-size-26;
            font-weight: normal;
            color: #949494;
            text-transform: initial;
        }
    }
}



/*******************************************************************************
* EMPLOI
*******************************************************************************/

#blocBackground_emploi {
    text-align: center;
    padding: 100px 0;
    background: url("../images/emploi_section01_bg.jpg") no-repeat;
    background-size: cover;
    margin-top: -8px;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #fefefe;
        line-height: 1.5;
    }
}



/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#blocCoordonnees {
    padding: 100px 0;
    display: flex;
    justify-content: space-around;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        padding-bottom: 15px;
    }
    p {
        font-size: $font-size-30;
        font-weight: normal;
        color: #000;
        line-height: 1.5;
    }

    .telCourriel {
    padding-top: 45px;

        p {
            line-height: 1;
        }
    }
}



/*******************************************************************************
* EXCAVATION
*******************************************************************************/

#blocBackground_excavation {
    text-align: center;
    padding: 100px 0;
    background: url("../images/exclavation_section01_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #fefefe;
    }
}

#blocRealisation_excavation {
    text-align: center;
    padding: 50px 0;
    background: url("../images/accueil_section03_bg.jpg") no-repeat;
    background-size: cover;

    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #242422;
    }
    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #242422;
        text-transform: initial;
    }
}



/*******************************************************************************
* DÉMOLITION
*******************************************************************************/

#blocBackground_demolition {
    text-align: center;
    padding: 100px 0;
    background: url("../images/demolition_section01_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #000;
        text-transform: initial;
        padding-bottom: 10px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #000;
        line-height: 1.5;
    }
    p.texte {
        padding-bottom: 50px;
    }
    .titre {
         padding-bottom: 50px;
    }
}



/*******************************************************************************
* AUTRES SERVICES
*******************************************************************************/

#blocBackground_services {
    text-align: center;
    padding: 100px 0;
    background: url("../images/autresservices_section01_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #fefefe;
    }
}

#blocServices {
    display: flex;
    padding: 100px 5%;

    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #242422;
        padding-top: 25px;
    }
    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #000000;
        background: url("../images/autresservices_section02_title.jpg") no-repeat;
        padding: 30px;
        text-align: center;
    }

    .bloc {
        width: 30%;
    }
    .blocMarge {
        margin-right: 85px;
    }
}



/*******************************************************************************
* NOS ÉQUIPEMENTS
*******************************************************************************/

#blocBackground_equipement {
    text-align: center;
    padding: 100px 0;
    background: url("../images/nosequipements_section01_bg.jpg") no-repeat;
    background-size: cover;

    h3 {
        font-size: $font-size-30;
        font-weight: bold;
        color: #ffce48;
        text-transform: initial;
        padding-bottom: 25px;
    }
    p {
        font-size: $font-size-18;
        font-weight: normal;
        color: #fefefe;
    }
}

#blocEquipement {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px;

    .bloc {
        width: 30%;
        margin: 30px auto;

        .image-wrapper {
          overflow: hidden;
          img {
            width: 100%;
            transition: .5s;
            &:hover {
              transform: scale(1.2);
              transition: .5s;
            }
          }
        }

        .texte {
            background: #242422;
            background-size: cover;
            padding: 20px;

            h3 {
                font-size: $font-size-30;
                font-weight: bold;
                color: #ffce48;
                text-transform: initial;
                padding-bottom: 15px;
            }
            p {
                font-size: $font-size-18;
                font-weight: normal;
                color: #fefefe;
                line-height: 1.5;
            }
        }
    }
}



/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#blocRealisations {
    padding: 50px 10% 100px 10%;

    .bloc {
            padding: 50px 0;

        .titre {
            background: #ffcc46;
            padding: 25px 50px;
            margin-bottom: 50px;

            h3 {
                font-size: $font-size-30;
                font-weight: bold;
                color: #000;
                }
        }

        .sous-titre {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 5%;
            margin-bottom: 35px;

            img {
                padding-right: 50px;
            }
            h3 {
                font-size: $font-size-30;
                font-weight: bold;
                color: #ffce48;
                transition: .5s;
                text-transform: initial;
            }
            &:hover {
              h3 {
                transition: .5s;
                color: $color-black;
              }
            }
        }

        .contenu {
                display: flex;
                padding: 0 5%;
                align-items: center;
                justify-content: center;
                div.image-wrapper {
                  width: 33%;
                  max-width: 500px;
                }
                div.desc-wrapper {
                  width: 66%;
                }

                p {
                font-size: $font-size-18;
                font-weight: normal;
                color: #000;
                padding-left: 10%;
            }
        }

        @media screen and (max-width: 1100px) {
            .titre h3 {
                text-align: center;
            }
            .sous-titre {
                justify-content: center;
            }
            div.contenu {
                div.image-wrapper, div.desc-wrapper {
                    width: 100%;
                    p {
                        text-align: center;
                    }
                }
            }
        }
    }
}






/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1550px) {

#bloc50-50 .right-side h3 {
    padding-bottom: 15px;
}
#bloc50-50 .right-side {
    padding-left: 5%;
}
#blocTexteBackground p br {
    display: none;
}
#blocTexteBackground {
    padding: 100px 50px 100px 60%;
}
}


@media screen and (max-width: 1500px) {
#blocLogo .logo {
    width: 20%;
    text-align: center;
    margin: auto;
}
}


@media screen and (max-width: 1400px) {

#bloc50-50 .right-side h4 {
    padding-bottom: 10px;
}
#bloc50-50 .right-side h3 {
    padding-bottom: 20px;
}
#blocBackground_excavation p br, #blocBackground_services p br, #blocBackground_equipement p br {
    display: none;
}
#bloc50-50 .right-side ul li {
    margin-bottom: -22px;
}
#blocBackground_services, #blocBackground_equipement {
    padding: 100px 25px;
}
#blocRealisations .bloc .contenu {
    padding: 0;
}
}


@media screen and (max-width: 1360px) {

nav.module-menu .menu-navbar .wrapper.left .logo {
    margin-left: -75px;
}
}


@media screen and (max-width: 1330px) {

#blocRealisations .bloc .contenu p {
    line-height: 1.7;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    margin-left: -75px;
}
}


@media screen and (max-width: 1300px) {

nav.module-menu .menu-navbar .main-menu-top .logoDroite {
    display: none;
}
#blocLogo .logo {
    width: 15%;
}
}


@media screen and (max-width: 1100px) {

#bloc50-50 .right-side {
    padding-left: 3%;
}
#bloc50-50 .right-side ul li {
    margin-bottom: -20px;
}
#bloc50-50 .right-side h4 {
    padding-bottom: 5px;
    padding-top: 20px;
}
#bloc50-50 .right-side h3 {
    font-size: 1.3rem;
}
#blocDifferences ::before {
    right: 0%;
}
#blocTexteBackground {
    padding: 100px 49% 100px 50px;
}
#bloc50-50 .right-side h3 {
    padding-bottom: 0px;
}
#blocBackground_demolition {
    padding: 100px 15px;
}
#blocBande {
    padding: 50px 25px;
}
#blocRealisations .bloc .contenu {
    flex-direction: column;
    align-items: center;
}
#blocRealisations .bloc .contenu img {
    padding-bottom: 25px;
}
}

@media screen and (max-width: $size-md-max) {

#blocCoordonnees {
    padding: 100px 35px;
    flex-direction: column;
}
#blocCoordonnees .heures {
    padding-top: 50px;
}
#blocBackground_excavation {
    padding: 100px 15px;
}
#bloc50-50 .right-side ul li {
    margin-bottom: -22px;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    margin-left: 0;
}
#blocServices {
    flex-direction: column;
}
}

@media screen and (max-width: $size-sm-max) {

#bloc50-50 {
    flex-direction: column;
}
#bloc50-50 .left-side {
    width: 100%;
}
#bloc50-50 .right-side {
    width: 100%;
    padding: 25px 10%;
}
#bloc50-50 .right-side ul li {
    margin-bottom: -15px;
}
.bursts .bursts-container {
    flex-direction: column;
}
.bursts .bursts-container .burst .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
}
.module-header--caption--subtitle {
    font-size: 1rem;
}
#blocDifferences ::before {
    display: none;
}
#bloc50-50_logo {
    flex-direction: column;
}
#blocTexteBackground {
    padding: 100px 50px;
}
#bloc50-50_logo .left-side {
    width: 100%;
    padding: 0 10% 0 10%;
}
#bloc50-50_logo .right-side {
    width: 100%;
    padding-top: 40px;
}
#blocEquipement {
    flex-direction: column;
}
#blocEquipement .bloc {
    width: 90%;
    padding-bottom: 50px;
}
#blocRealisations .bloc .contenu p {
    padding-left: 0;
}
#blocServices .bloc {
    width: 75%;
    padding-bottom: 25px;
    margin: auto;
}
}

@media screen and (max-width: $size-xs-max) {

#blocBackground {
    padding: 100px 20px;
}
#bloc50-50 .right-side h3 br {
    display: none;
}
#bloc50-50 .right-side {
    padding: 25px 9%;
}
.texteBanniere .title h2, .texteBanniere .subtitle h2 {
    font-size: 2.5rem;
    margin-bottom: 5px;
}
#blocBackground_sommes p br {
    display: none;
}
#blocBackground_sommes {
    padding: 50px 15px;
}
#blocFormulaire #right {
    text-align: left;
}
#blocBackground_emploi {
    padding: 100px 15px;
}
#blocBackground_excavation, #blocBackground_demolition, #blocBackground_services, #blocBackground_equipement {
    padding: 60px 15px;
}
#blocRealisation_excavation {
    padding: 50px 20px;
}
#blocEquipement .bloc {
    width: 100%;
}
#blocLogo {
    flex-direction: column;
}
#blocLogo .logo {
    width: 55%;
    margin: 30px 0;
}
#blocServices .bloc {
    width: 100%;
}
#bloc50-50_logo .blocLogo {
    flex-direction: column;
    padding-top: 10px;
}
}

@media screen and (max-width: 400px) {

#blocIntro_sommes {
    padding: 65px 10px;
}
#blocIntro_sommes p {
    width: 88%;
}
#blocDifferences h3 br {
    display: none;
}
#blocDifferences .bloc {
    padding-left: 40px;
}
#bloc50-50_logo .blocLogo {
    flex-direction: column;
}
#bloc50-50_logo .blocLogo .logo img {
    padding-top: 15px;
}
#bloc50-50_logo .left-side {
    padding: 0 5% 0 5%;
}
#blocCoordonnees {
    padding: 100px 25px;
    flex-direction: column;
}
}
