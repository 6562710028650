.bursts {
    width: 100%;
    .bursts-container {
        @extend .flex;
        .burst {
            //flex: 1;
            position: relative;
            .text-container, .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-container {
                padding: 1.25rem;
                .border {
                    width: 100%;
                    height: 100%;
                    @extend .flex;
                    @extend .flex-column;
                    @extend .justify-center;
                    @extend .items-center;
                    transition: all 0.2s ease-out; // OUT effect

                    p {
                    color: #fff;
                    font-size: $font-size-30;
                    font-weight: bold;
                    }
                }
            }
            .overlay {
                opacity: 0;
                transition: all 0.7s ease-out; // OUT effect
            }
            h3,h4 {
                color: $color-white;
                transition: all 0.2s ease-out; // OUT effect
            }
            h3 {
                @extend .font-2-normal;
                font-size: 4vw;
                font-weight: 600;
                text-transform: none;
            }
            h4 {
                @extend .font-1-normal;
                font-size: 2.5vw;
            }
        }
        a:hover .burst {
            .overlay {
                opacity: 1;
                transition: all 0.3s ease; // IN effect
            }
            h3, h4 {
                color: $color-secondary;
                transition: all 0.3s ease; // IN effect

            }
            .border {
                //border-color: $color-secondary;
                transition: all 0.3s ease; // IN effect
            }
        }
    }
}
@media screen and (max-width: $size-slider-breakpoint) {
    .bursts {
        .bursts-container {
            flex-direction: column;
            .burst {
                h3 {
                    font-size: 2rem;
                }
                h4 {
                    font-size: 1rem;
                }
            }
        }
    }
}