
div.wrap {
  padding: 50px 0;

  section.header_calc {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-grey;
    p.smallCalc {
      color: $color-grey;
      font-weight: 400;
      font-style: italic;
    }
  }

  @media screen and (min-width: 421px) {
      .radio_btns-item {
          display: inline-block;
          vertical-align: middle;
          margin-right: 55px;
      }

      .radio_btns-item:last-child {
          margin-right: 0;
      }
  }

  div.calcul {


    div.radio_btns {
      padding: 20px 0 55px;
      color: #6E6E6E;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      div.radio_btns-item {
        margin-right: 50px;
        input[type='radio']{
            /* Hide the input, but have it still be clickable */
           opacity: 0;
           margin-bottom: 10px;
        }

        input[type='radio'] + label,
        input[type='checkbox'] + label {
            margin: 0;
            clear: none;
            /* Left padding makes room for image */
            padding: 5px 0 4px 40px;
            /* Make look clickable because they are */
            cursor: pointer;
            background-image: url(../images/spritesheet-radio.jpg);
            background-repeat:no-repeat;
            background-position: 0 0;
            //width: 32px;
            height: 25px;
            position: relative;
            left: -25px;
        }

        /*
            Change from unchecked to checked graphic
        */
         input[type='radio']:checked + label {
            background-image: url(../images/spritesheet-radio.jpg);
            background-repeat:no-repeat;
            background-position: 0px -42px;
            //width: 32px;
            height: 25px;
            position: relative;
        }
      }
    }


    div.values {
      display: flex;
      align-items: center;
      justify-content: space-around;



      .values-item-ctn {
          width: calc(99.9% / 3);
          padding: 0 50px;
          box-sizing: border-box;
      }

      @media screen and (max-width: 964px) {
        flex-direction: column;
          .values-item-ctn {
              width: 100%;
              padding: 0 20px;
              padding-bottom: 40px;
          }
      }

      .values-item {
          padding:10px 0;
      }

      .values-item-inner {
          position: relative;
      }

      .values-item-inner input{
          position: relative;
          z-index: 10;
          width: 100%;
          padding: 10px;
          text-align: center;
          border: none;
          outline:none;
          -moz-outline:none;
          -webkit-outline:none;
          font-size: 20px;
          line-height: 28px;
          color: #251a06;
          opacity: 0;
          background-color: transparent;


          box-sizing: border-box;

          transition: opacity ease 300ms 0ms;
      }

      @media screen and (max-width: 964px) {
          .values-item-inner input {
              padding-top: 20px;
              padding-bottom: 20px;
          }
      }

      .values-item-inner input:focus,
      .values-item .values-item-inner input {
          opacity: 1;
          border: none !important;
          appearance: none;

          transition-delay: 300ms;
      }

       input[type=number]::-webkit-inner-spin-button,
       input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
      }

      .values-item-inner label {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          display: block;
          width: 100%;
          color: #251a06;
          pointer-events: none;
      }

      .values-item-inner label span {
          display: block;
          text-align: center;
          padding: 14px 0;
          font-weight: bold;
      }

      @media screen and (max-width: 964px) {
          .values-item-inner label span {
              padding: 24px 0;
          }
      }

      .values-item-inner input:focus + label span,
      .values-item.filled .values-item-inner label span {
          -webkit-animation: anim-value-label 300ms forwards;
              animation: anim-value-label 300ms forwards;
      }
      .values-item-inner input:focus + label:before,
      .values-item-inner input:focus + label:after,
      .values-item.filled .values-item-inner label:before,
      .values-item.filled .values-item-inner label:after {
          border-color: #ffc500;
      }

      .values-item-inner label:before, .values-item-inner label:after {
          content: '';
          position: absolute;
          top: 0;
          width: 50%;
          height: 100%;
          border: 2px solid #d6dddf;
          box-sizing: border-box;
          transition: transform ease 300ms 300ms, border-color ease 300ms 300ms;
      }

      .values-item-inner label:before {
          border-right: none;
          left: 0;
      }

      .values-item-inner input:focus + label:before,
      .values-item.filled label:before {
          transform: translateX(-32px);

          transition-delay: 0ms;
      }

      .values-item-inner label:after {
          border-left: none;
          left: 50%;
      }

      .values-item-inner input:focus + label:after,
      .values-item.filled label:after {
                  transform: translateX(32px);
                  transition-delay: 0ms;
      }

      .values-item-inner label span.imperial{display:none;}



      @keyframes anim-value-label {
          50% {
              opacity: 0;
              -webkit-transform: scale3d(0.3, 0.3, 1);
              transform: scale3d(0.3, 0.3, 1);
          }
          51% {
              opacity: 0;
              -webkit-transform: translate3d(0, 55px, 0) scale3d(0.3, 0.3, 1);
              transform: translate3d(0, 55px, 0) scale3d(0.3, 0.3, 1);
          }
          100% {
              opacity: 1;
              -webkit-transform: translate3d(0, 55px, 0);
              transform: translate3d(0, 55px, 0);
          }
      }

    }


    div.result {
      margin: 50px 0;
      text-align: center;
      background: url(../images/accueil_section03_bg.jpg) no-repeat;
      background-size: cover;


      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;

    }

    .values-item-inner label span.imperial{display:none;}

      @media screen and (max-width: 964px) {
          .result {
              padding-bottom: 15px;
              flex-direction: column;
          }
      }

      .result .smallCalc{
          //display: inline-block;
          color:#000;
          padding:20px;
          margin:0;
          white-space: nowrap;
          @media screen and (max-width: 768px) {
            width: 100%;
            //display: block;
            padding: 10px;
            text-align: center;
            box-sizing: border-box;
        }
      }



      .result .result-right {
          width: 100%;
          display: flex;
          align-items: center;
          @media screen and (max-width: 964px) {
              justify-content: center;
              padding-top: 0;
              @media screen and (max-width: 600px) {
                  flex-direction: column;
              }
          }
      }



      .result .field_result{
          display: inline-block;
          padding: 0px 10px 5px;
          margin-left:20px;
          margin-right:20px;
          background-color:transparent;
          border-top:0;
          border-right:0;
          border-left:0;
          border-bottom:1px solid #59647d;
          color:#000;
          font-size:1em;
      }

      @media screen and (max-width: 964px) {
          .result .field_result {
              margin-right: 15px;
              padding-top: 20px;
              //width: calc(99.9% - 40px);
              box-sizing: border-box;
          }
      }

      @media screen and (max-width: 480px) {
          .result .field_result::-webkit-input-placeholder {
              opacity: 0;
          }

          .result .field_result :-moz-placeholder {
              opacity: 0;
          }

          .result .field_result::-moz-placeholder {
              opacity: 0;
          }

          .result .field_result:-ms-input-placeholder {
              opacity: 0;
          }
      }

      .result .result_currency{
          display: inline-block;
          color:#000;
          font-weight: 400;
      }
      .result .result_currency span{
          display:none;
      }
      .result .result_currency span:first-child{
          display:block;
      }
      .result .result_currency::first-letter {
          text-transform: uppercase;
      }

      @media screen and (max-width: 964px) {
          .result .result_currency {
              margin: 10px 20px 0;
              white-space: nowrap;
              margin-left: 0;
          }
      }



  }
}






/*page estimation other site*/
